export default class Ajax {
    hhtc_request(url,data){
        var requrl=url;
        // var requrl="https://video.honghukeji.net/"+url
        var init={
            method: 'POST',
            headers:{
                'Content-Type': 'application/json;charset=UTF-8',
                "Accept": "application/json",
                "token" : localStorage.getItem('tk'),
            },
            body:JSON.stringify(data)
        }
        // console.log(localStorage.getItem('token:','tk'))
        return  fetch(requrl,init).then(
             (response) => {
                return  response.json()
            }
        ).then(
            (data)=>{
                return data;
            }
        ).catch(
            (err)=>{
                console.log("执行一次网络请求:请求地址=>",requrl,"返回结果=>网络异常",err)
                var data={
                    code:0,
                    msg:'网络异常!'
                }
                return data;
            }
        )
    }
}
