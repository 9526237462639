import React from 'react'

export default class Video extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lan: '',//语言
            id: '',//视频id
            token: '',//token
            avatar: '',//头像
            name: '',//昵称
            userId: '',//用户id
            thumbUrl: '',//视频封面图
            videoUrl: '',//视频播放地址
        }
    }
    componentDidMount() {
        window.getParams(obj => {
            this.state.id = obj.id//视频id
            this.state.lan = obj.lan//语言类型
            localStorage.setItem('tk', obj.token);
            console.log(localStorage.getItem('tk'))
            console.log(obj)
            this.getinfo()
        })
    }
    getinfo() {
        global.Ajax.hhtc_request('index/home/getShareVideoInfo', {
            langType: 1,
            id: this.state.id
        }).then(res => {
            if (res.code == 1) {
                this.setState({
                    name: res.data.nickName,//昵称
                    avatar: res.data.avatar,//头像
                    userId: res.data.userTag,//用户id
                    thumbUrl: res.data.thumbUrl,//封面图
                    videoUrl: res.data.videoUrl,//视频链接
                })
                console.log(res)
            }
        })
    }
    judgeClient() {
        let client = '';
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            var loadDateTime = new Date();  //判断iPhone|iPad|iPod|iOS
            window.location = 'honghao://yuancaitong.app/openwith';
            window.setTimeout(() => { //如果没有安装app,便会执行setTimeout跳转下载页
                var timeOutDateTime = new Date();
                if (timeOutDateTime - loadDateTime < 5000) {
                    // window.location = "..."; //ios下载地址  
                    this.props.history.push('/home')//下载地址
                } else {
                    window.close();
                }
            }, 500);
        } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
            //   client = 'Android';
            window.location = 'honghao://yuancaitong.app/openwith';
            window.setTimeout(function () {
                // window.location = "..."; //android下载地址  
                this.props.history.push('/home') //下载地址  
            }, 500);
        }
        return client;
    }

    render() {
        return (
            <div className='VideoShare'>
                <p className='spot1'></p>
                <p className='spot2'></p>
                <div className='maxbox'>
                    <div className='topbox'>
                        <video width="100%" poster={this.state.thumbUrl} src={this.state.videoUrl} controls className='topimg'></video>
                    </div>
                    <div className='botbox'>
                        <div className='minbox'>
                            <img className='logpic' src={this.state.avatar} alt='' />
                            <div>
                                <p className='name'>{this.state.name}</p>
                                <p className='userid'>ID:{this.state.userId}</p>
                            </div>
                            {this.state.lan == 1 &&
                                // onClick={() => {this.props.history.push("/home")}}
                                <div className='codebox' onClick={() => {
                                    this.judgeClient()
                                }}>立即下载</div>
                            }
                            {this.state.lan == 2 &&
                                <div className='codebox' onClick={() => { this.judgeClient() }}>Download now</div>
                            }
                        </div>
                        <div className='bottext'>
                            {this.state.lan == 1 &&
                                <p className='bottext'>- 下载APP，查看TA的主页 -</p>
                            }
                            {this.state.lan == 2 &&
                                <p className='bottext'>- Download app and view TA's home page -</p>
                            }
                        </div>
                        {/* honghao://yuancaitong.app/openwith */}
                    </div>
                </div>
            </div>
        )
    }
}