import React from 'react';
// 引入 ECharts 主模块
import ReactEcharts from 'echarts-for-react'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lan: '',//
            active: 1,
            type: '1',//1按天查询 2按周查询 3按月查询
            xPlay: [],
            yPlay: [],
            xVideo: [],
            yVideo: [],
            bontlist: [
                { id: '1', name: '天' },
                { id: '2', name: '周' },
                { id: '3', name: '月' }
            ],
            bontUS: [
                { id: '1', name: 'day' },
                { id: '2', name: 'week' },
                { id: '3', name: 'month' }
            ],
        }
    }
    componentDidMount() {
        window.getParams(obj => {
            console.log(obj)
            this.state.lan = obj.lan
            localStorage.setItem('tk', obj.token);
            this.getPlay()//播放数据
            this.getVideo()//发布频率
            this.getEcharts1()
            this.getEcharts2()
        })
        console.log(window.location.href)
    }
    // 获取播放数据
    getPlay() {
        global.Ajax.hhtc_request('index/user/getPlayData', {
            langType: this.state.lan,
            type: this.state.type
        }).then(res => {
            if (res.code === 1) {
                this.setState({
                    yPlay: res.data.datas,
                    xPlay: res.data.dates,
                })
            }
        })
    }
    //发布数据
    getVideo() {
        global.Ajax.hhtc_request('index/user/getVideoPlayData', {
            langType: this.state.lan,
        }).then(res => {
            if (res.code == 1) {
                console.log(res.data)
                this.setState({
                    yVideo: res.data.datas,
                    xVideo: res.data.dates
                })
            }
        })
    }
    // 
    getEcharts1() {
        var echarts1 = {
            xAxis: {
                "axisLine": {
                    "show": false
                },
                type: 'category',
                boundaryGap: false,
                // data: ['05/01', '05/02', '05/03', '05/04', '05/05', '05/06', '05/07']
                data: this.state.xPlay,
            },
            tooltip: {
                axisPointer: {
                    type: 'none'
                },
                backgroundColor: '#fff',
                trigger: 'axis',
                textStyle: { // 提示框浮层的文本样式。
                    color: '#2C2C2C',
                    fontStyle: 'normal',
                    fontWeight: 'Bold',
                    fontFamily: 'PingFang SC',
                    fontSize: 12,
                },
                nameStyle: {
                    color: '#9999',

                },
            },
            yAxis: {
                splitLine: {    //网格线
                    lineStyle: {
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
                "axisLine": {       //y轴
                    "show": false
                },
                type: 'value'
            },
            series: [
                {
                    // data: [500, 400, 651, 788, 765, 400, 200],
                    data: this.state.yPlay,
                    symbol: 'circle',
                    // symbolSize:'9',
                    name: '播放量',
                    type: 'line',
                    itemStyle: {
                        normal: {
                            color: "#00C6FF",
                            lineStyle: {
                                color: "#00C6FF"
                            }
                        }
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1, color: '#FFF' // 0% 处的颜色
                            }, {
                                offset: 0, color: '#00C6FF' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                    }
                }
            ]
        };
        return echarts1
    }
    getEcharts2() {
        var echarts2 = {
            color: ['#00C6FF'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                backgroundColor: '#fff',
                trigger: 'axis',
                textStyle: { // 提示框浮层的文本样式。
                    color: '#2C2C2C',
                    fontStyle: 'normal',
                    fontWeight: 'Bold',
                    fontFamily: 'PingFang SC',
                    fontSize: 12,
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    "axisLine": {       //y轴
                        "show": false
                    },
                    // data: ['05/01', '05/02', '05/03', '05/04', '05/05', '05/06', '05/07'],
                    data: this.state.xVideo,
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    splitLine: {    //网格线
                        lineStyle: {
                            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: true //隐藏或显示
                    },
                    type: 'value',
                    "axisLine": {       //y轴
                        "show": false
                    },
                }
            ],
            series: [
                {
                    name: '发布量',
                    textStyle: { color: '#999', },
                    type: 'bar',
                    barWidth: '60%',
                    itemStyle: {
                        normal: {
                            barBorderRadius: [50, 50, 0, 0]
                        }
                    },
                    // data: [10, 52, 200, 334, 390, 330, 220]
                    data: this.state.yVideo,
                }
            ]
        };
        return echarts2
    }
    render() {
        return (
            <div className='DataBoard'>
                <div className='space'>
                    <div className='Titlebox'>
                        <div style={{ display: 'flex' }}>
                            <p className='p1'></p>
                            <p className='p2'></p>
                            {this.state.lan == 1 &&
                                <div className='title1'>播放数据</div>
                            }
                            {this.state.lan == 2 &&
                                <div className='title1'>Play data</div>
                            }
                        </div>
                        {/* <div className='title2'>单位:100</div> */}
                    </div>
                    {this.state.lan == 1 &&
                        <div className='topbox'>
                            {this.state.bontlist.map((item, index) => {
                                return (
                                    <p key={'' + index} onClick={() => {
                                        this.setState({
                                            type: index + 1
                                        }, () => {
                                            this.getPlay()
                                        })
                                    }}
                                        className={`blue ${this.state.type - 1 === index ? 'blues' : ''}`}>{item.name}</p>
                                )
                            })}
                        </div>
                    }
                    {this.state.lan == 2 &&
                        <div className='topbox'>
                            {this.state.bontUS.map((item, index) => {
                                return (
                                    <p key={'' + index} onClick={() => {
                                        this.setState({
                                            type: index + 1
                                        }, () => {
                                            this.getPlay()
                                        })
                                    }}
                                        className={`blue ${this.state.type - 1 === index ? 'blues' : ''}`}>{item.name}</p>
                                )
                            })}
                        </div>
                    }
                    <div className='Echarts1box'>
                        <ReactEcharts
                            option={this.getEcharts1()}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{ width: '100%', height: '310px' }}
                        />
                    </div>
                </div>
                <div>
                    <div className='Titlebox'>
                        <div style={{ display: 'flex' }}>
                            <p className='p1'></p>
                            <p className='p2'></p>
                            {this.state.lan == 1 &&
                                <div className='title1'>发布频率</div>
                            }
                            {this.state.lan == 2 &&
                                <div className='title1'>Release frequency</div>
                            }
                        </div>
                        {/* <div className='title2'>单位:1</div> */}
                    </div>
                    <div className='Echarts2box'>
                        <ReactEcharts
                            option={this.getEcharts2()}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{ width: '100%', height: '300px' }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}