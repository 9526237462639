import React from 'react'
import {
  Route,
  Switch,
  HashRouter as Router,
  Redirect,
} from 'react-router-dom';
import './App.css';
import './index.css';
import Home from './component/Home/Home';
import Index from './component/Home/Index';
import Video from './component/Home/Video';
import Data from './component/Home/Data';
import Agreement from './component/Home/agreement';

import Ajax from './class/Ajax';
global.Ajax=new Ajax();


export default class App extends React.Component {


	render(){
		return(
			<React.Fragment>
				<Router>
					<Switch>
						<Route path="/index" component={Index} />
						<Route path="/video" component={Video} />
						<Route path="/home" component={Home} />
						<Route path="/data" component={Data} />
						<Route path="/agreement" component={Agreement} />

						<Redirect to='/index'/>
					</Switch>
				</Router>
			</React.Fragment>
		)
	}
}
