import React from 'react'

export default class Video extends React.Component{
    constructor(props){
        super(props)
        this.state={
            //ID 13用户协议 14隐私政策 15社会自律公约 16关务我们
            id:'',
            Content:'',
        }
    }

    componentDidMount(){
        window.getParams(obj => {
            this.state.id = obj.id//
            // this.state.lan = obj.lan
            console.log(obj)
            this.getContent()
        })
        
    }
    getContent(){
        global.Ajax.hhtc_request('index/home/xieyi', {
            id:this.state.id,
            // langType: this.state.lan,
        }).then(res => {
            if (res.code == 1) {
                this.setState({
                    Content:res.data.content
                })
                console.log(res.data)
            }
        })
    }

    render(){
        return(
            <div className='Content'>
                <div className='ContentText' dangerouslySetInnerHTML={{ __html: this.state.Content }}></div>
            </div>
        )
    }
}