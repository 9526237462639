import React from 'react'
import bg from '../../img/bg.png'
import log from '../../img/log.png'
import logs from '../../img/logs.png'
import pic1 from '../../img/pic1.png'
import pic2 from '../../img/pic2.png'


export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lan:'',//
      android:'',
      ios:'',
    }
  }
  componentDidMount(){
    window.getParams(obj => {
      // this.state.id = obj.id //获取用户id
      this.state.lan=obj.lan//
      localStorage.setItem('tk',obj.token);
      console.log(obj)
      this.get()
    })
  }
  get(){
    global.Ajax.hhtc_request('index/home/getDowanLoadUrl', {}).then(res => {
        if (res.code == 1) {
            console.log(res.data.nickName)
            this.setState({
              android:res.data.android,
              ios:res.data.ios
            })
        }
    })
  }
  
  render() {
    return (
      <div className='Index'>
          <img className='bgimg' src={bg} alt='' />
        <div className='logsbox'>
          <img className='log1' src={logs} alt='' />
          <img className='log2' src={log} alt='' />
          {this.state.lan==1&&
          <p className='log3'>记录你的美好生活</p>
          }
          {this.state.lan==2&&
          <p className='log3'>Record your good life</p>
          }
        </div>
        <div className='maxbox' >
          <div className='box' style={{ marginTop: '60px' }}>
            <img className='pic' src={pic1} alt='' />
            {this.state.lan==1&&
            <a href={this.state.android} rel="noopener noreferrer">android下载</a>
            }
            {this.state.lan==2&&
            <a href={this.state.android} rel="noopener noreferrer">Android Download</a>
            }
          </div>
          <div className='box' style={{ marginTop: '60px' }}>
            <img className='pic' src={pic2} alt='' />
            {this.state.lan==1&&
            <a href={this.state.ios} rel="noopener noreferrer">iPhone下载</a>
            }
            {this.state.lan==2&&
            <a href={this.state.ios} rel="noopener noreferrer">IPhone Download</a>
            }
          </div>
        </div>
      </div>
    )
  }
}
