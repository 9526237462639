import React from 'react'
import indexBg from '../../img/indexBg.png'
import icon from '../../img/index-icon.png'
import img from '../../img/imgs.png'
import { Tabs, Carousel } from 'antd';


export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lan: '',//语言类型
            id: '',//用户id
            token: '',//
            avatar: '',//头像
            name: '',//昵称
            userId: '',//ID
            userBg: '',//背景图
            zanNum: '',
            floowNum: '',
            fansNum: '',
            interduce: '',
            banners: [],
            userTags: [],
        }
    }

    componentDidMount() {
        window.getParams(obj => {
            this.state.id = obj.id //获取用户id
            this.state.lan = obj.lan//
            localStorage.setItem('tk', obj.token);
            console.log(obj)
            this.getinfo()
        })

    }
    // 获取数据
    getinfo() {
        global.Ajax.hhtc_request('index/home/getSharePersonInfo', {
            id: this.state.id,
            langType: this.state.lan,
        }).then(res => {
            if (res.code == 1) {
                console.log(res.data.nickName)
                this.setState({
                    avatar: res.data.avatar,
                    name: res.data.nickName,//名字
                    userId: res.data.userTag,
                    userBg: res.data.userBg,
                    banners: res.data.banners,
                    zanNum: res.data.zanNum,
                    floowNum: res.data.floowNum,
                    fansNum: res.data.fansNum,
                    userTags: res.data.userTags,
                    interduce: res.data.interduce
                })
            }
        })
    }

    //判断是android还是ios
    judgeClient() {
        let client = '';
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            var loadDateTime = new Date();  //判断iPhone|iPad|iPod|iOS
            window.location = 'honghao://yuancaitong.app/openwith';
            window.setTimeout(() => { //如果没有安装app,便会执行setTimeout跳转下载页
                var timeOutDateTime = new Date();
                if (timeOutDateTime - loadDateTime < 5000) {
                    // window.location = "..."; //ios下载地址  
                    this.props.history.push('/home')//下载地址
                } else {
                    window.close();
                }
            }, 500);
        } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
            //   client = 'Android';
            window.location = 'honghao://yuancaitong.app/openwith';
            window.setTimeout(function () {
                // window.location = "..."; //android下载地址  
                this.props.history.push('/home') //下载地址  
            }, 500);
        }
        return client;
    }

    render() {
        return (
            <div className='indexShare'>
                <p className='spot1'></p>
                <p className='spot2'></p>
                <div className='maxbox'>
                    <div className='topbox'>
                        {this.state.userBg == '' &&
                            <img className='topimg' src={indexBg} alt='' />
                        }
                        {this.state.userBg != '' &&
                            <img className='topimg' src={this.state.userBg} alt='' />
                        }
                    </div>
                    <div className='Boxtop'>
                        <div className='titleBox'>
                            <p className='title'>{this.state.name}</p>
                            <img className='Icon' src={icon} alt='' />
                            <div className='UserLog'>
                                <img className='UserImg' alt='' src={this.state.avatar} />
                            </div>
                        </div>
                        {this.state.lan == 1 &&
                            <div className='dataBox'>
                                <div className='p1'>获赞<span>{this.state.zanNum}</span></div>
                                <div className='p1'>关注<span>{this.state.floowNum}</span></div>
                                <div className='p1'>粉丝<span>{this.state.fansNum}</span></div>
                            </div>
                        }
                        {this.state.lan == 2 &&
                            <div className='dataBox'>
                                <div className='p1'>Get praise<span>{this.state.zanNum}</span></div>
                                <div className='p1'>follow<span>{this.state.floowNum}</span></div>
                                <div className='p1'>fans<span>{this.state.fansNum}</span></div>
                            </div>
                        }
                        <div className='bqBox'>
                            {this.state.userTags.map((row, index) => {
                                return (
                                    <div className='bqmin' key={index}>
                                        <p>{row}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='jianjieBox'>
                            {/* <p>这里是简介是简介简介是简介简介是简介简介是简介简 介是简介这里是简介是简介简介是简介简介是简介简介是简介简 介是简介</p> */}
                            <p>{this.state.interduce}</p>
                        </div>
                        <div className='lunboBox'>
                            {this.state.banners.length > 0 &&
                                <Carousel dots={true} autoplay  >
                                    {this.state.banners.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <img className='lbImg' alt='' src={item} />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            }
                        </div>
                        <div className='infoBox'>
                            <div>
                                <img className='avatar' src={this.state.avatar} alt='' />
                            </div>
                            <div style={{ marginLeft: '8.5px' }}>
                                <p className='t1'>{this.state.name}</p>
                                <p className='t2'>ID:{this.state.userId}</p>
                            </div>
                            {this.state.lan == 1 &&
                                <div className='bnt' onClick={() => { this.judgeClient() }}>立即下载</div>
                            }
                            {this.state.lan == 2 &&
                                <div className='bnt' onClick={() => { this.judgeClient() }}>Download now</div>
                            }
                        </div>
                        <div className='bottext'>
                            {this.state.lan == 1 &&
                                <p className='bottext'>- 下载APP，查看TA的主页 -</p>
                            }
                            {this.state.lan == 2 &&
                                <p className='bottext'>- Download app and view TA's home page -</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}